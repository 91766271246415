<template>
  <b-overlay
    variant="white"
    :show="isLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".5"
    rounded="sm"
  >
    <b-row class="match-height">
      <b-col cols="12">
        <b-card :title="this.$route.meta.title">
          <b-card-body class="p-0">
            <b-row>
              <b-col cols="12" md="6" class="mb-2">
                <h6>No / Tanggal SPPD</h6>
                <p class="card-text mb-25">
                  {{ sppd.sppd_number || '-' }} /
                  {{ sppd.letter_date || '-' | moment('DD MMMM YYYY') }}
                </p>
              </b-col>
              <b-col cols="12" md="6" class="mb-2">
                <h6>
                  {{ employee.type === 'pegawai' ? 'NIPPOS' : 'NIK' }} / Nama
                </h6>
                <p class="card-text mb-25">
                  {{
                    employee.type === 'pegawai' ? employee.nippos : employee.nik
                  }}
                  /
                  {{
                    employee.type === 'pegawai' ? employee.nama : employee.name
                  }}
                </p>
              </b-col>
              <b-col cols="12" md="6" class="mb-2">
                <h6>Jenis Fasilitas</h6>
                <p class="card-text mb-25">
                  {{ sppdFacilityType }}
                </p>
              </b-col>
              <b-col cols="12" md="6" class="mb-2">
                <h6>Jabatan</h6>
                <p class="card-text mb-25">
                  {{
                    employee.type === 'pegawai'
                      ? employee.descjabatan
                      : employee.position
                  }}
                </p>
              </b-col>
              <b-col cols="12" md="6" class="mb-2">
                <h6>Bagian/Unit Kerja</h6>
                <p class="card-text mb-25">
                  {{ employee.namabagian || '-' }}
                </p>
              </b-col>
              <b-col cols="12" md="6" class="mb-2">
                <h6>Kantor/Kota Tujuan</h6>
                <p class="card-text mb-25">
                  {{ sppd.destination_office || '-' }}
                </p>
              </b-col>
              <b-col cols="12" md="6" class="mb-2">
                <h6>Tanggal Berangkat</h6>
                <p class="card-text mb-25">
                  {{ sppd.departure_date | moment('DD MMMM YYYY') }}
                </p>
              </b-col>
              <b-col cols="12" md="6" class="mb-2">
                <h6>Tanggal Kembali</h6>
                <p class="card-text mb-25">
                  {{ sppd.return_date | moment('DD MMMM YYYY') }}
                </p>
              </b-col>
              <b-col cols="12" md="6" class="mb-2">
                <h6>Menggunakan Agen Travel</h6>
                <b-row>
                  <b-col cols="12" xl="8">
                    <b-form-select
                      v-if="Object.keys(sppd).length && !sppd.accomodation_step"
                      v-model="selectedTravelAgent"
                      :options="travelAgentOptions"
                    ></b-form-select>
                    <p v-else class="card-text mb-25">
                      {{ mitra }}
                    </p>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <div
              v-if="
                (!sppd.accomodation_step && !selectedTravelAgent) ||
                  (sppd.accomodation_step && !sppd.mitra_accomodation_id)
              "
            >
              <div
                v-if="
                  Object.keys(sppd).length &&
                    !sppd.accomodation_step &&
                    sppd.general_status === 'Sudah melakukan perjalanan'
                "
                class="d-flex align-items-start justify-content-between mb-2"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  class="mr-1"
                  variant="outline-primary"
                  @click="tambahBiaya"
                >
                  <plus-icon size="1x" />
                  <span> Tambah Biaya</span>
                </b-button>
              </div>
              <b-table
                striped
                hover
                responsive
                show-empty
                :items="items"
                :fields="fields"
              >
                <template #cell(no)="data">{{ data.index + 1 }}</template>

                <template #cell(total_cost)="data">
                  <span class="text-capitalize d-block text-right">
                    {{ data.item.total_cost | currency }}
                  </span>
                </template>

                <template #cell(file)="data">
                  <b-button :href="data.item.file" variant="info" target="_blank">
                    Cek Kwitansi
                  </b-button>
                </template>

                <!-- A virtual column -->
                <template #cell(action)="data">
                  <div style="min-width: 120px">
                    <b-button
                      v-if="role_name === 'Pegawai'"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="warning"
                      size="sm"
                      class="btn-icon mr-1"
                      @click="gotoEditPage(data.item.id)"
                    >
                      <feather-icon icon="EditIcon" />
                    </b-button>

                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="danger"
                      size="sm"
                      class="btn-icon"
                      @click="confirmDelete(data.item.id)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </div>
                </template>
              </b-table>
              <div class="mt-2">
                <h6>Total Biaya</h6>
                <h6>{{ totalCost | currency }}</h6>
              </div>
            </div>
          </b-card-body>
          <b-card-footer align="right" class="px-0">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              :to="{ name: 'biaya-akomodasi' }"
            >
              Kembali
            </b-button>
            <b-button
              v-if="Object.keys(sppd).length && !sppd.accomodation_step"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              class="ml-1"
              variant="primary"
              @click="sendData"
            >
              {{ items.length ? 'Kirim' : 'Simpan' }}
            </b-button>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardFooter,
  BRow,
  BCol,
  BButton,
  BTable,
  BFormSelect,
  BOverlay,
} from 'bootstrap-vue'
import { PlusIcon } from 'vue-feather-icons'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'

export default {
  components: {
    BCard,
    BCardBody,
    BCardFooter,
    BRow,
    BCol,
    BButton,
    BTable,
    BFormSelect,
    PlusIcon,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    const { sppdId } = this.$route.params

    return {
      sppdId,
      sppd: {},
      config: {
        api: '/sppd',
      },
      fields: [
        'no',
        { key: 'note', label: 'Uraian' },
        { key: 'total_cost', label: 'Biaya' },
        { key: 'file', label: 'Kwitansi' },
      ],
      items: [],
      selectedTravelAgent: null,
      travelAgentOptions: [{ value: null, text: 'Tidak Ada' }],
      isLoading: false,
      role_name: JSON.parse(localStorage.getItem('userData')).role_name,
    }
  },
  computed: {
    totalCost() {
      let total = 0
      this.items.forEach(item => {
        total += item.total_cost
      })
      return total
    },
    sppdFacilityType() {
      if (this.sppd.sppd_facility_type_id == 1) {
        return 'Perjalanan Dinas Biasa'
      }
      if (this.sppd.sppd_facility_type_id == 2) {
        return 'Perjalanan Dinas Fasilitas Khusus'
      }
      return '-'
    },
    employee() {
      return this.sppd.employee?.raw ? JSON.parse(this.sppd.employee.raw) : {}
    },
    mitra() {
      return this.sppd.mitra_accomodation?.agen_name || 'Tidak Ada'
    },
  },
  mounted() {
    const { query } = this.$route
    if (Object.keys(query).length) {
      if (query.event) {
        this.makeToast(query.event, query.title, query.text)
      }
      this.$router.replace({ query: null })
    }

    this.isLoading = true;
    this.getAccommodations()
    this.getMitras()
  },
  methods: {
    getAccommodations() {
      axios.get(`${this.config.api}/${this.sppdId}/accomondation`).then(res => {
        const { data } = res.data
        if (!data.accomodation_step) {
          this.fields.push({
            key: 'action',
            label: 'Aksi',
            thStyle: 'width: 120px',
          })
        }
        this.sppd = data
        this.items = data.accomondations
      })
      .catch(error => {
        console.error(error)
        this.isLoading = false

        var message = "";
        if(typeof error.response.data.message === 'object'){
          message = error.response.data.message.errors[0].msg
        } else {
          message = error.response.data.message
        }
        this.makeToast('danger', "Terjadi Kesalahan", message)
      })
    },
    getMitras() {
      axios.get('/misc/mitras?status=1').then(res => {
        const options = res.data.data.map(({ id, agen_name }) => ({
          value: id,
          text: agen_name,
        }))
        this.travelAgentOptions = [...this.travelAgentOptions, ...options]
        this.isLoading = false
      })
      .catch(error => {
        console.error(error)
        this.isLoading = false

        var message = "";
        if(typeof error.response.data.message === 'object'){
          message = error.response.data.message.errors[0].msg
        } else {
          message = error.response.data.message
        }
        this.makeToast('danger', "Terjadi Kesalahan", message)
      })
    },
    tambahBiaya() {
      this.$router.push({
        name: 'add-accommodation',
        params: { sppdId: this.sppdId },
        query: { folder: this.sppd.folder },
      })
    },
    sendData() {
      let title = 'Catatan'
      let text =
        'Tidak mengisi biaya/kwitansi, dapat diartikan bahwa tidak ada biaya yang Anda keluarkan selama perjalanan dinas ini'

      if (this.items.length) {
        title = 'Perhatian'
        text =
          'Saya menyatakan dokumen yang diupload adalah benar/sah. Jika tidak benar, maka saya bersedia dikenakan sanksi/hukdis sesuai dengan ketentuan yang berlaku'
      }

      this.$swal({
        title,
        text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya, setuju',
        cancelButtonText: 'Tidak, batal',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-2',
        },
      }).then(result => {
        if (result.value) {
          const data = {
            mitra_accomodation_id: this.selectedTravelAgent,
          }
          this.isLoading = true
          axios
            .put(`${this.config.api}/${this.sppdId}/accomondation/submit`, data)
            .then(() => {
              this.isLoading = false
              this.fields.pop()
              this.getAccommodations()
              this.makeToast(
                'success',
                'Update Biaya Akomodasi',
                'Biaya akomodasi berhasil dikirim',
              )
            })
            .catch(err => {
              this.isLoading = false
              if (err.response) {
                this.$swal({
                  icon: 'Danger',
                  title: 'Gagal mengirim data',
                  text: err.response.data.message,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        }
      })
    },
    makeToast(variant = null, title = 'Title here...', text = 'Text here...') {
      this.$bvToast.toast(text, {
        title,
        variant,
        solid: true,
      })
    },
    gotoEditPage(id) {
      this.$router.push({
        name: 'edit-accommodation',
        params: { sppdId: this.sppdId, sppdAccommodationId: id },
        query: { folder: this.sppd.folder },
      })
    },
    confirmDelete(id) {
      this.$swal({
        title: 'Apakah Anda Yakin',
        text: 'untuk menghapus data?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isLoading = true
          axios
            .delete(`${this.config.api}/accomondation/${id}`)
            .then(() => {
              this.isLoading = false
              this.$swal({
                icon: 'success',
                title: 'Berhasil Dihapus',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.items = this.items.filter(item => item.id !== id)
            })
            .catch(err => {
              this.isLoading = false
              if (err.response) {
                this.$swal({
                  icon: 'Danger',
                  title: 'Tidak bisa di hapus',
                  text: err.response.data.message,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>
